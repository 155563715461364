import { render, staticRenderFns } from "./CampaignGeneratorDetails.vue?vue&type=template&id=031637d9&flex=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VApp,VAvatar,VCard,VCardSubtitle,VCardTitle,VCol,VContainer,VDataTable,VDivider,VImg,VProgressCircular,VRow,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/sam-mono/sam-mono/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('031637d9')) {
      api.createRecord('031637d9', component.options)
    } else {
      api.reload('031637d9', component.options)
    }
    module.hot.accept("./CampaignGeneratorDetails.vue?vue&type=template&id=031637d9&flex=true&", function () {
      api.rerender('031637d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CampaignGeneratorDetails.vue"
export default component.exports