var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part" },
          [
            _c(
              "div",
              { staticClass: "top-page-header page-header-with-banner" },
              [
                _c("div", { staticClass: "head-title top-space" }, [
                  _c("h1", [_vm._v("Campaign Generator")]),
                ]),
              ]
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "py-4 px-8 rounded-lg extra-padding",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c(
                        "v-row",
                        {},
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                lg: "5",
                                md: "6",
                                sm: "12",
                                xl: "5",
                                cols: "12",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "pa-2 rounded-lg outline d-flex flex-row align-center",
                                  attrs: { flat: "", elevation: "0" },
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "ma-0",
                                    attrs: {
                                      "max-width": "50",
                                      src: require("../assets/images/kochava.png"),
                                    },
                                  }),
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "text-h5 pa-0 d-flex flex-column mx-3 align-start",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-h5 font-weight-bold text--primary ma-0",
                                        },
                                        [_vm._v(" EasyWebinar ")]
                                      ),
                                      _c(
                                        "v-card-subtitle",
                                        { staticClass: "title-1 pa-0" },
                                        [_vm._v("CPT Bid : $40.00")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "progress-bar" },
                                    [
                                      _c(
                                        "v-progress-circular",
                                        {
                                          attrs: {
                                            value: 50,
                                            size: 55,
                                            width: 6,
                                            color: "pink",
                                          },
                                        },
                                        [_vm._v("50% ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex pr-5",
                              attrs: {
                                lg: "7",
                                md: "6",
                                sm: "12",
                                xl: "7",
                                cols: "12",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center w-100 justify-space-between",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center flex-column",
                                    },
                                    [
                                      _c("span", [_vm._v(" Gender ")]),
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [_vm._v("Female")]
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { attrs: { vertical: "" } }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center flex-column",
                                    },
                                    [
                                      _c("span", [_vm._v("Age")]),
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [_vm._v("50")]
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { attrs: { vertical: "" } }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center flex-column",
                                    },
                                    [
                                      _c("span", [_vm._v(" Device")]),
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [_vm._v("I-Phone")]
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { attrs: { vertical: "" } }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center flex-column",
                                    },
                                    [
                                      _c("span", [_vm._v(" Geo/Region")]),
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [_vm._v("India")]
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", { attrs: { vertical: "" } }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center flex-column",
                                    },
                                    [
                                      _c("span", [_vm._v("User Type")]),
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [_vm._v("New User")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-12 mb-9" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "horizontal-timeline pl-6",
                              attrs: {
                                lg: "8",
                                md: "6",
                                sm: "12",
                                xl: "8",
                                cols: "12",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "timeline-circle active-circle",
                              }),
                              _c("v-divider", {
                                staticClass: "timeline-hr active-hr",
                                attrs: { Horizontal: "" },
                              }),
                              _c("div", {
                                staticClass: "timeline-circle active-circle",
                              }),
                              _c("v-divider", {
                                staticClass: "timeline-hr active-hr",
                                attrs: { Horizontal: "" },
                              }),
                              _c("div", {
                                staticClass: "timeline-circle active-circle",
                              }),
                              _c("v-divider", {
                                staticClass: "timeline-hr active-hr",
                                attrs: { Horizontal: "" },
                              }),
                              _c("div", {
                                staticClass: "timeline-circle active-circle",
                              }),
                              _c("v-divider", {
                                staticClass: "timeline-hr active-hr",
                                attrs: { Horizontal: "" },
                              }),
                              _c("div", { staticClass: "timeline-circle" }),
                              _c("v-divider", {
                                staticClass: "timeline-hr",
                                attrs: { Horizontal: "" },
                              }),
                              _c("div", { staticClass: "timeline-circle" }),
                              _c("v-divider", {
                                staticClass: "timeline-hr",
                                attrs: { Horizontal: "" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex flex-column align-end pr-6",
                              attrs: {
                                lg: "4",
                                md: "6",
                                sm: "12",
                                xl: "4",
                                cols: "12",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("span", [_vm._v(" Gender ")]),
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v(" : Female"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("span", [_vm._v("Age ")]),
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v(": 50"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center mt-8 mb-4" },
                              [
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      staticClass: "custom-tab",
                                      attrs: {
                                        "background-color": "transparent",
                                      },
                                      model: {
                                        value: _vm.tab,
                                        callback: function ($$v) {
                                          _vm.tab = $$v
                                        },
                                        expression: "tab",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tab",
                                        [
                                          _vm._v(" Campaign "),
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "primary",
                                                size: "26",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text caption",
                                                },
                                                [_vm._v("12")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tab",
                                        [
                                          _vm._v(" Add Groups "),
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "primary",
                                                size: "26",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text caption",
                                                },
                                                [_vm._v("4")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tab",
                                        [
                                          _vm._v(" Keywords "),
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass: "mx-3",
                                              attrs: {
                                                color: "primary",
                                                size: "26",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text caption",
                                                },
                                                [_vm._v("2")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tabs-items",
                                    {
                                      model: {
                                        value: _vm.tab,
                                        callback: function ($$v) {
                                          _vm.tab = $$v
                                        },
                                        expression: "tab",
                                      },
                                    },
                                    _vm._l(3, function (i) {
                                      return _c(
                                        "v-tab-item",
                                        {
                                          key: i,
                                          attrs: { value: "tab-" + i },
                                        },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "elevation-1",
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }